var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"detail-header",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{attrs:{"src":require("../../assets/img/zhang/back.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.titleName))])]),_c('div',{staticClass:"page"},[_c('Breadcrumb',{attrs:{"routerList":_vm.routerList}}),_c('div',{staticClass:"selectBox",staticStyle:{"margin-top":"20px"}},[_c('PlatformCom',{ref:"platform_type",attrs:{"desc":'platform_type'}}),_c('PlatformCom',{ref:"body_type",attrs:{"desc":'body_type'}}),_c('el-row',{staticStyle:{"position":"relative"},attrs:{"gutter":20}},[_c('el-col',{staticClass:"selectBox-group-left",attrs:{"span":20}},[_c('SearchBar',{ref:"SearchBar",attrs:{"listType":Number(_vm.$route.query.listType)}})],1),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"selectBox-group-btn"},[_c('div',{staticClass:"queryBtn",on:{"click":_vm.toSearch}},[_vm._v("查询")]),_c('div',{staticClass:"resetBtn",on:{"click":_vm.resetFn}},[_vm._v("重置")]),_c('div',{staticClass:"exportBtn",on:{"click":_vm.exportDakai}},[_c('img',{attrs:{"src":require("../../assets/img/zhang/export.png"),"alt":""}}),_c('span',[_vm._v("数据导出")])])])])],1)],1),_c('el-card',{staticClass:"platform",attrs:{"shadow":"never"}},[_c('el-skeleton',{attrs:{"rows":26,"loading":_vm.ifOpen}}),_c('el-table',{staticClass:"m-t",staticStyle:{"width":"100%","text-align":"center","border":"1px solid #e4e6ec","border-bottom":"none"},attrs:{"stripe":"","data":_vm.queryList,"header-cell-style":{
          'text-align': 'center',
          background: '#F6F7FA',
          color: '#333',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{staticClass:"textHidden1",attrs:{"prop":_vm.listData[0].prop,"label":_vm.listData[0].label,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"cursor",staticStyle:{"color":"#409EFF"},on:{"click":function($event){return _vm.toDetail2(scope.row)}}},[_vm._v(_vm._s(scope.row[_vm.listData[0].prop] || scope.row[_vm.listData[0].beiyong]))])]}}])}),_vm._l((_vm.listData.filter(
            (v, i) => i !== 0
          )),function({ prop, label },index){return _c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"show-overflow-tooltip":""}})})],2),_c('Pagination',{attrs:{"pageNumber":_vm.page.page,"pageSize":_vm.page.limit,"total":_vm.total},on:{"SizeChange":_vm.SizeChange,"CurrentChange":_vm.CurrentChange}})],1),_c('exportDialog',{ref:"exportDialog"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }