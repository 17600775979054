<template>
  <div>
    <div class="detail-header" @click="$router.go(-1)">
      <img src="../../assets/img/zhang/back.png" alt="" />
      <span>{{ titleName }}</span>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <!-- 标题信息 -->
      <!-- <div class="page-title">
    <div class="page-title-left"></div>
  </div> -->
      <!-- 筛选 -->
      <div class="selectBox" style="margin-top:20px">
        <!-- 平台类型 -->
        <PlatformCom ref="platform_type" :desc="'platform_type'"></PlatformCom>
        <!-- 主体类型 -->
        <PlatformCom ref="body_type" :desc="'body_type'"></PlatformCom>
        <!-- 认证情况 -->
        <!-- 条件栏 -->
        <el-row :gutter="20" style="position: relative">
          <el-col :span="20" class="selectBox-group-left">
            <!-- 标签 -->
            <!-- 粉丝 -->
            <!-- 月发文数量 -->
            <!-- 选择条件 -->
            <!-- 所在地区 -->
            <!-- 问题类型 -->
            <SearchBar
              ref="SearchBar"
              :listType="Number($route.query.listType)"
            />
            <!-- <MessageType
          ref="MessageType"
          class="selectBox-group-item" 
          :title="'问题类型'"
        ></MessageType> -->
          </el-col>
          <el-col :span="4">
            <div class="selectBox-group-btn">
              <div class="queryBtn" @click="toSearch">查询</div>
              <div class="resetBtn" @click="resetFn">重置</div>
              <div class="exportBtn" @click="exportDakai">
                <img src="../../assets/img/zhang/export.png" alt="" /><span
                  >数据导出</span
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 添加异常平台按钮 -->
      <!-- 列表 -->
      <el-card shadow="never" class="platform">
        <!-- 加载中 -->
        <el-skeleton :rows="26" :loading="ifOpen" />
        <!-- 位图 --> 
        <el-table
          class="m-t"
          stripe
          :data="queryList"
          :header-cell-style="{
            'text-align': 'center',
            background: '#F6F7FA',
            color: '#333',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%;text-align: center;border: 1px solid #e4e6ec;border-bottom: none;"
        >
          <el-table-column
          class="textHidden1" 
            :prop="listData[0].prop"
            :label="listData[0].label"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span style="color:#409EFF;" class="cursor" @click="toDetail2(scope.row)">{{ scope.row[listData[0].prop] || scope.row[listData[0].beiyong] }}</span>
              
              <!-- <el-link @click="toDetail2(scope.row)" type="primary"  class="textHidden1" >{{ scope.row[listData[0].prop] }}</el-link> -->
            </template>
          </el-table-column>

          
          <el-table-column
            v-for="({ prop, label }, index) in listData.filter(
              (v, i) => i !== 0
            )"
            :key="index"
            :prop="prop"
            :label="label"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <Pagination
          :pageNumber="page.page"
          :pageSize="page.limit"
          @SizeChange="SizeChange"
          @CurrentChange="CurrentChange"
          :total="total"
        />
      </el-card>

      <!-- 添加异常平台 弹框 -->
      <!-- 数据导出 -->
      <exportDialog ref="exportDialog"></exportDialog>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import SearchBar from "./searchBar.vue";
import MessageType from "../../components/dressing/MessageType.vue"; // 问题类型
import PlatformCom from "../../components/dressing/PlatformCom.vue"; //  筛选框 全选多选
import exportDialog from "../../components/exportDialog.vue";
import Left from "./components/left.vue";
import Pagination from "../../components/pagination";
import Bitmap from "../../components/bitmap.vue"; // 位图
import { youhai, cuowu, wenti } from "@/api/chuzhijilu";
import { exportExcel, account } from "../../api/platformaccount/index";
import { addAbnormalPlatform, count } from "../../api/Problem/index";
import { listData } from "./data";
export default {
  components: {
    Breadcrumb,
    Pagination, // 分页
    Bitmap, // 位图
    Left,
    exportDialog, // 导出
    PlatformCom, // 多选框子
    MessageType, // 有害信息的类型,
    SearchBar,
  },
  data() {
    return {
      routerList: [this.$route.query.titleName, "处置记录"], //面包屑
      listData: listData(this.$route.query.listType),
      ifOpen: true, // 加载中
      // 筛选栏目的信息
      myInfo: {
        textNum: null, //月发文数量
        textSection: true, //月发文数量区间
        content: "", //搜索内容
        address: "", //所在地区
        addressList: [{ label: "陕西", value: 0 }], //地区
        labelList: [],
      },
      queryList: [], //列表
      page: {
        page: 1,
        limit: 10,
      },
      total: 0,
      titleName: this.$route.query.titleName,
    };
  },
  mounted() {
    this.queryPage();
  },
  created() {
    const obj = {
      1: "/Problem",
      2: "/harmful",
      3: "/error",
    };
    this.$route.meta.activePath = obj[this.$route.query.listType];
  },
  methods: {
    toDetail2(row) {
      const obj ={
        1:{
          path:'/questionDetail',
          data:row
        },
        2:{
          path:'/harmfulDetail',
          data:row
        },
        3:{
          path:'/errorDetail',
          data:{  id: row.id,
              platformType: row.platformTypeName,
              bodyType: row.bodyType,
              publishTime: row.publishTime,
              planName: row.planName,
            }
        }
      }
      if(this.$route.query.listType==2){
        console.log(row);
        this.$main.routeResolve("/harmfulDetail", { id:row.dataId ,activeName:'2'});
        return
      }
      let routeData = this.$router.resolve({
        path: obj[this.$route.query.listType].path,
        query: {
          info: encodeURIComponent(
            JSON.stringify({...obj[this.$route.query.listType].data,isChuzhi:true})
          ),
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 列表
    async queryPage(myInfo) {
      this.ifOpen = true;
      this.queryList = [];
      const { page } = this; 
      console.log(page)
      if(this.$route.query.listType ==1) {
        page.pageNo =  page.page
        page.pageSize  =  10
        delete page.page
        delete page.limit
      } 
      console.log(page)
      const item = {
        ...page,
        // abnormal: true,
        // statHarm:true,
      };

      try {
        myInfo = { ...myInfo }; // 这是选择器
        const Apifn = {
          2: youhai,
          3: cuowu,
          1: wenti,
        };
        const Res = await Apifn[this.$route.query.listType].list(
          myInfo ? { ...myInfo, ...item } : item
        );
        const {
          data: { list, totalCount },
        } = Res.data;
        this.ifOpen = false;
        this.queryList = list;
        this.total = Number(totalCount);
      } catch {
        this.ifOpen = false;
      }
    },
    exportDakai() {
      this.toSearch({ ifReturn: 1 });
    },
    // 导出
    async exportFN(obj) {
      //                        请求函数，参数配置,组件配置
      const apiFn = {
        1: {
          fn: wenti.daochu,
          text: "问题平台处置记录.xlsx",
        },
        2: {
          fn: youhai.daochu,
          text: "有害信息处置记录.xlsx",
        },
        3: {
          fn: cuowu.daochu,
          text: "错误表述处置记录.xlsx",
        },
      };
      await this.$main.exportFN(
        apiFn[this.$route.query.listType].fn,
        {
          ...obj,
          // abnormal: true
        },
        this.$refs,
        1,
        apiFn[this.$route.query.listType].text
      );
    },
    yiwancheng() {
      this.$router.push("/completed");
    },
    chuzhijilu() {
      this.$router.push("/chuzhijilu");
    },
    // 列表标签 方法
    async ChangeFn(item, e) {
      const { accountId, platformType, labelIds } = item;
      const Res = await account({
        labelIds: labelIds ? labelIds + "," + e : e,
        accountId,
        platformType,
      });
      const { code } = Res.data;
      if (code == 200) {
        this.$message({
          message: `修改标签成功`,
          type: "success",
        });
      } else {
        this.$message.error("修改失败");
      }
    },
    //跳转详情
    toDetail(inx, item) {
      // 1:处置详情 跳详情页
      // 2：查看详情 平台台账详情
      // 3：内容信息 跳发布内容列表页
      // 4: 有害信息 跳向有害信息列表
      const routeObj = {
        1: { path: "/questionDetail", query: { item } },
        2: { path: "/accountDetail", query: item },
        3: { path: "/publishingcontent", query: { ...item, pageType: 1 } },
        4: { path: "/harmful", query: { ...item, pageType: 1 } },
      };
      const { path, query } = routeObj[inx];
      this.$main.routeResolve(path, query);
    },
    // 查询
    toSearch({ ifReturn, ifpageType } = {}) {
      //  type 存在 代表会返回成对象
      //  pageType 存在 代表是分页按钮点击的  如果不存在 那么分页默认第一页
      if (!ifpageType) {
        this.page.page = 1;
      }
      const { page, $refs } = this;
      const obj = {
        platformTypes: $refs.platform_type.formObj.checkedform.map(
          (v) => v.value
        ), //平台类型
        bodyTypes: $refs.body_type.formObj.checkedform.map((v) => v.value), //主体类型
        // messageTypeList: $refs.MessageType.status, // 有害信息类型
        ...$refs.SearchBar.getData(),
      };
      if (this.$route.query.listType == 3)
        obj.bodyTypes = obj.bodyTypes.join(",") || ""; // 处置状态
      if (this.$route.query.listType == 3)
        obj.platformTypes = obj.platformTypes.join(",") || ""; // 处置状态
      if (this.$route.query.listType == 1) {
        obj.platformTypeList = obj.platformTypes;
        delete obj.platformTypes;
        obj.bodyTypeList = obj.bodyTypes;
        delete obj.bodyTypes;
      }

      // type 为1 调用导出
      if (ifReturn) {
        this.exportFN({ ...obj, ...page });
        return;
      }
      this.queryPage(obj);
    },
    // 重置
    resetFn() {
      // this.$refs.MessageType.status = [];
      this.$refs.SearchBar.resetCom();
      this.$refs.platform_type.resetCom(); // 平台类型
      this.$refs.body_type.resetCom(); // 主体类型
      this.toSearch();
    },
    // 分页  页码
    CurrentChange(val) {
      this.page.page = val;
      this.toSearch({ ifpageType: 1 });
    },
    // 分页  条数
    SizeChange(val) {
      this.page.limit = val;
      this.page.page = 1;
      this.toSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-header {
  width: 100%;
  height: 56px;
  background-color: #ffffff;
  display: flex;
  justify-content: start;
  img {
    width: 38px;
    height: 38px;
    margin: 9px 15px 9px 23px;
    cursor: pointer;
  }
  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 56px;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
}
.page-title {
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 24px;
  line-height: 36px;
  font-weight: 600;
  font-family: PingFangSC-Medium, PingFang SC;
  .page-title-left {
    font-size: 26px;
  }
  .page-title-right {
    font-size: 18px;
    cursor: pointer;
    i {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
// 处置状态
.content-box {
  display: flex;
  min-height: 52px;
  width: 100%;
  height: auto;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  border-bottom: 1px solid #e4e6ec;
  .content-box-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 16px;
    width: 96px;
    height: 100%;
  }
  .content-box-right {
    display: flex;
    width: calc(100% - 96px);
    height: 100%;
    .platform-check-all {
      //全选
      margin: 16px 48px 0px 0px;
      ::v-deep .el-checkbox__label {
        color: #333333;
      }
      ::v-deep .el-checkbox__inner {
        border-color: #e4e6ec;
      }
      ::v-deep .el-checkbox__input.is-checked {
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
      ::v-deep .el-checkbox__input.is-indeterminate {
        //半选
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
    }
    .platform-group {
      width: calc(100% - 100px);
      height: 100%;
      padding-bottom: 16px;
      .platform-check-more {
        //多选
        margin: 16px 48px 0px 0px;
        ::v-deep .el-checkbox__label {
          color: #333333;
        }
        ::v-deep .el-checkbox__inner {
          border-color: #e4e6ec;
        }
      }
      ::v-deep .el-checkbox.check-more.is-checked {
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
    }
    ::v-deep .el-radio {
      margin-top: 18px;
      margin-right: 34px;
      .el-radio__label {
        color: #333333;
      }
      .el-radio__inner {
        border-color: #e4e6ec;
      }
      .el-radio__input.is-checked {
        .el-radio__inner {
          background-color: #f58030;
        }
      }
    }
  }
}

.queryBtn {
  width: 60px;
  height: 36px;
  background-color: #f58030;
  color: white;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;
}
.resetBtn {
  width: 60px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  color: #000;
  border: 1px solid #e1e1e1;
  margin-right: 16px;
  cursor: pointer;
}
.exportBtn {
  width: 97px;
  height: 36px;
  line-height: 32px;
  text-align: center;
  background-color: #fff1e8;
  border-radius: 4px;
  color: #f58030;
  cursor: pointer;
  img {
    height: 14px;
    width: 14px;
    margin-right: 2px;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}
.addAbnormal {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.addAbnormalBtn {
  float: right;
  width: 128px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  text-align: center;
  background-color: #f58030;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin: 16px 0;
}
.platform {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  .platformInfo {
    position: relative;
    display: flex;
    justify-content: start;
    width: 100%;
    height: 156px;
    padding: 24px 0;
    border-bottom: 1px solid #e4e6ec;
    .platformInfo-left {
      width: 130px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-right: 24px;
      .platformInfo-left-top {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #fff2e9;
        margin: 10px 0 9px 0;
      }
      .platformInfo-left-bottom {
        padding: 0px 22px;
        width: auto;
        height: 24px;
        background: #f58030;
        color: white;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
      }
    }
    .platformInfo-middle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // width: 1080px;
      width: calc(100% - 100px);
      height: 100%;
      // margin-right: 110px;
      .middle-name {
        width: 80%;
        height: 25px;
        line-height: 25px;
        font-size: 18px;
        font-weight: 600;
      }
      .middle-species {
        display: flex;
        justify-content: start;
        // width: 135%;
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        .mr32 {
          display: flex;
          margin-right: 32px;
          span {
            display: block;
            max-width: 180px;
            min-width: 20px;
          }
          .colRed {
            color: red;
            max-width: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .middle-select {
        span {
          margin-right: 6px;
        }
      }
    }
    .platformInfo-right {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // width: 286px;
      width: auto;
      height: 100%;
      padding: 24px 0;
      .platformInfo-right-top {
        text-align: right;
        font-size: 14px;
        color: #e60b1e;
        font-family: PingFangSC-Medium, PingFang SC;
      }
      .platformInfo-right-bottom {
        display: flex;
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 14px;
        .desc {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            margin-right: 3px;
          }
          div {
            display: inline-block;
          }
        }
      }
    }
  }
}
.region {
  display: flex;
  align-items: center;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 46px;
}
</style>
