const listData = (listType) => {
  const click = {
    1: [
      {
        prop: "accountName",
        label: "账号名称",
        beiyong: "accountId",
      },
    ],
    2: [
      {
        prop: "title",
        label: "文章标题",
      },
      {
        prop: "author",
        label: "作者",
      },
    ],
    3: [
      {
        prop: "title",
        label: "文章标题",
      },
      {
        prop: "author",
        label: "作者",
      },
    ],
  };
  const leiixn = {
    1: [
      {
        prop: "abnormalTypeName",
        label: "问题类型",
      },
    ],
    2: [
      {
        prop: "msgTypeText",
        label: "信息类型",
      },
    ],
    3: [
      {
        prop: "planName",
        label: "错误类型",
      },
      {
        prop: "disposeUser",
        label: "处置人员",
      },
    ],
  };
  const rnm = {
    1: "platformName",
    2: "platformTypeText",
    3: "platformTypeName",
  };
  const rnm2 = {
    1: "updateTime",
    2: "disposeDate",
    3: "disposeTime",
  };
  const rnm3 = {
    1: "disposeStatusName",
    2: "disposeResultText",
    3: "disposalContent",
  };
  const list = [
    ...click[listType],
    {
      prop: rnm[listType],
      label: "平台类型",
    },
    {
      prop: listType == 2 ? "bodyTypeText" : "bodyTypeName",
      label: "主体类型",
    },
    ...leiixn[listType],
    {
      prop: rnm2[listType],
      label: "处置时间",
    },
    {
      prop: rnm3[listType],
      label: "处置结果",
    },
  ];
  return list;
};
export { listData };
