//  处置记录
import request from "../request.js";
function youhaiList(data) {
  return request({
    url: "/module-listener-complex/harmfulDisposeRecord/list",
    method: "post",
    data: { ...data, disposeStatus: 2 },
  });
}
function youhaidaochu(data) {
  return request({
    url: "/module-listener-complex/harmfulDisposeRecord/exportExcel",
    method: "post",
    data: { ...data, disposeStatus: 2 },
    responseType: "blob",
  });
}
export const youhai = { list: youhaiList, daochu: youhaidaochu };
function cuowuList(data) {
  return request({
    url: "/module-listener-complex/planMisreDisposalRecords/list",
    method: "post",
    data,
  });
}
function cuowudaochu(data) {
  return request({
    url: "/module-listener-complex/planMisreDisposalRecords/exportExcel",
    method: "post",
    data,
    responseType: "blob",
  });
}
export const cuowu = { list: cuowuList, daochu: cuowudaochu };  
function wentiList(data) {
  return request({
    url: "/module-account/account/queryDisposalComplete",
    method: "post",
    data,
  });
}
function wentidaochu(data) {
  return request({
    url: "/module-account/account/exportDisposalComplete",
    method: "post",
    data,
    responseType: "blob",
  });
}
export const wenti = { list: wentiList, daochu: wentidaochu };
export function cwlxfb(data) {
    return request({
        url: "/module-web/statistics/misreAnomalyFigure",
        method: "post",
        data,
    })
}
export function overallStatistics(data) {
    return request({
        url: "module-web/statistics/overallStatistics",
        method: "post",
        data,
    })
}


// 统计页面------------------------------------------------------------------------------
// 错误表述统计（近一年）
export function misreAnomalyStatistics(data) {
    return request({
        url: "module-web/statistics/misreAnomalyStatistics",
        method: "post",
        data
    })
}
//问题账号类型分布（问题平台）
export function abnormalAccountStat(data) {
    return request({
        url: "module-web/statistics/abnormalAccountStat?startTime=" + data.startTime + "&endTime=" + data.endTime,
        method: "get"
    })
}
//问题平台增长趋势（近一年）
export function abnormalAccountTrend() {
  return request({
    url: "module-web/statistics/abnormalAccountTrend",
    method: "get"
  })
}
// 问题账号类型分布（有害信息）
export function harmfulPlatformStat(data) {
  return request({
    url: "/module-web/statistics/harmfulPlatformStat?startTime=" + data.startTime + "&endTime=" + data.endTime,
    method: "get"
  })
}
//发布账号TOP10
export function harmfulAccountStat() {
  return request({
    url: "module-web/statistics/harmfulAccountStat",
    method: "get"
  })
}

